import { Button, Drawer, Form, Input, Select } from "antd";
import TemplateRenderDataProvider from "screens/designStudio/hooks/TemplateRenderDataProvider";
import styles from "./ArchiveMarketingMaterialDrawer.module.scss";
import { Outlet, useParams } from "react-router-dom";
import Preview from "screens/adLibrary/marketingMaterialDrawer/Preview";
import { useFetchArchives } from "shared/hooks/salesEnablement/useFetchArchives";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import LocationsInput from "shared/components/LocationsInput";
import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import ExpandableParagraph from "shared/components/ExpandableParagraph";
import MarketingMaterialFormComponent from "screens/adLibrary/marketingMaterialDrawer/MarketingMaterialFormComponent";
import { ProductTypeOfferInput } from "screens/adLibrary/marketingMaterialDrawer/marketingMaterialForm/ProductTypeOfferInput";
import { useGetInitialValues } from "screens/adLibrary/marketingMaterialDrawer/hooks/useGetInitialValues";
import { useForm } from "antd/lib/form/Form";
import { FormProvider } from "shared/hooks/useFormInstance";
import { Languages } from "utils/helpers.salesEnablement";

const ArchiveMarketingMaterialDrawer = () => {
  const { data } = useFetchArchives();
  const navigate = useNavigateWithSearch();
  const { archiveId } = useParams<{ archiveId: string }>();
  const { marketingMaterial, templateData, pdfUrl } =
    (data ?? []).find(archive => archive.id === archiveId) ?? {};

  const { initialValues, disclosureFieldId, hasProductLayer } =
    useGetInitialValues(
      templateData,
      marketingMaterial,
      marketingMaterial?.language ?? "en",
    );

  const [form] = useForm();

  if (!data || !marketingMaterial || !templateData) return null;

  const onClose = () => {
    navigate("..");
  };

  const templateFile = templateData?.files[marketingMaterial.language];
  return (
    <TemplateRenderDataProvider
      file={{ ...templateFile, status: "done" }}
      material={marketingMaterial}
      forceUrl={pdfUrl}
    >
      <Drawer
        title={`Edit Marketing Material`}
        width="calc(100vw - 48px)"
        visible={true}
        onClose={onClose}
        closable={false}
        destroyOnClose
        footer={
          <div className={styles.footer}>
            <Button onClick={onClose}>Close</Button>
          </div>
        }
      >
        <div className={styles.drawer}>
          <FormProvider form={form}>
            <Form<MarketingMaterialTableItem>
              form={form}
              id={"archive-marketing-material-form"}
              layout="vertical"
              initialValues={initialValues}
              style={{ overflow: "auto", paddingRight: "24px" }}
            >
              <Form.Item name="isArchiveForm" hidden initialValue={true}>
                <Input hidden />
              </Form.Item>
              <ExpandableParagraph>
                {templateData?.description}
              </ExpandableParagraph>
              <Form.Item
                label="Marketing Material Name"
                name="name"
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input placeholder="Name" disabled />
              </Form.Item>
              <Form.Item
                label="State(s) to be Distributed In (Ind) / Account Situs State (Grp)"
                name="locations"
                required
              >
                <LocationsInput
                  availableLocations={marketingMaterial?.locations ?? []}
                  disabledLocations={[]}
                  disabledTooltip="This marketing material can't be used in this location"
                  disabled
                />
              </Form.Item>
              <Form.Item label="Language" name="language" required>
                <Select disabled>
                  <Select.Option
                    key={marketingMaterial?.language}
                    value={marketingMaterial?.language ?? "en"}
                  >
                    {Languages[marketingMaterial.language].label}
                  </Select.Option>
                </Select>
              </Form.Item>
              {disclosureFieldId && !hasProductLayer && (
                <ProductTypeOfferInput disabled />
              )}
              <MarketingMaterialFormComponent
                disabled
                file={templateFile}
                language={marketingMaterial.language ?? "en"}
              />
            </Form>
          </FormProvider>
          <Preview selectedFile={templateFile} />
        </div>
      </Drawer>
      <Outlet />
    </TemplateRenderDataProvider>
  );
};

export default ArchiveMarketingMaterialDrawer;
