import {
  CopyOutlined,
  EditOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useToggle } from "shared/hooks/useToggle";
import { successNotification } from "shared/components/customNotification/Notification";
import { useCellContext } from "./CellContext";
import styles from "./EditCell.module.scss";
import { useEditableCell } from "../hooks/useEditableCell";
import useOutsideClick from "shared/hooks/useOutsideClick";

type Props = {
  value: string | number;
  onSave: (
    value: string | number,
    oldValue: string | number,
    disableConfirm?: boolean,
  ) => void;
  onApplyToAll?: () => void;
};

const EditCell = ({ value, onSave, onApplyToAll }: Props) => {
  const [editing, toggleEditing] = useToggle(false);
  const { newValue, setNewValue, setOldValue } = useCellContext();
  const [textAreaValue, setTextAreaValue] = useState(value);

  const {
    handleToggleClicked,
    handleTextAreaChange,
    handleKeyDown,
    handleEscape,
  } = useEditableCell(undefined, undefined, onSave);

  const targetRef = useOutsideClick(() => {
    handleToggleClicked();
  });

  useEffect(() => {
    setNewValue(value);
    setOldValue(value);
  }, [value, setNewValue, setOldValue]);

  useEffect(() => {
    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [handleEscape]);

  const rowsNumber =
    typeof newValue === "string" ? Math.ceil(newValue.length / 20) : 0; // assuming 20 characters per row

  return (
    <div>
      <div className={styles.editCellContainer}>
        <span className={styles.editCell} ref={targetRef}>
          {editing && (
            <Input.TextArea
              className={styles.textArea}
              rows={rowsNumber}
              value={textAreaValue}
              onChange={e => {
                handleTextAreaChange(e, setTextAreaValue);
              }}
              onKeyDown={handleKeyDown}
            />
          )}
          {!editing && (
            <div className={styles.notEditableContainer}>
              <div className={styles.iconContainer}>
                <Tooltip title="Edit">
                  <EditOutlined
                    onClick={e => {
                      toggleEditing();
                      e.stopPropagation();
                    }}
                    className={styles.editIcon}
                  />
                </Tooltip>
                <Tooltip title="Copy to clipboard">
                  <CopyOutlined
                    onClick={() => {
                      navigator?.clipboard?.writeText(newValue.toString());
                      successNotification({
                        messageLabel: "Copied to clipboard",
                        size: "big",
                      });
                    }}
                    className={styles.copyIcon}
                  />
                </Tooltip>
                {onApplyToAll && (
                  <Tooltip title="Apply to all">
                    <InteractionOutlined
                      onClick={onApplyToAll}
                      className={styles.copyIcon}
                    />
                  </Tooltip>
                )}
              </div>
              <div className={styles.valueContainer}>{newValue}</div>
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default EditCell;
