import { InputNumber, type InputNumberProps } from "antd";
import styles from "./InputPhone.module.scss";
import { isNil } from "lodash";

type Props = Readonly<
  {
    value?: string;
    onChange?: (value: string) => void;
  } & InputNumberProps<string>
>;

function InputPhone({ value, onChange, ...rest }: Props) {
  if (!onChange) throw new Error("onChange is required");

  const getPhoneAsStringOfNumbers = (formattedValue: string | undefined) => {
    return formattedValue?.replace(/\D/g, "");
  };

  const parser = (value: string | undefined): string => {
    // I had to use this hack because the onChange event was not being triggered when the parsed value was ""
    if (!value) return "-1";
    const number = getPhoneAsStringOfNumbers(value);

    return number?.slice(0, 10) ?? "";
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Tab", "Backspace", "Delete", "Enter"].includes(e.key) || e.metaKey) {
      return;
    }
    if (!/[\d-]/.test(e.key) || value?.length === 12) {
      e.preventDefault();
    }
  };

  return (
    <InputNumber<string>
      className={styles.removeHandlers}
      value={value}
      onChange={value => {
        if (isNil(value)) return onChange("");
        onChange(formatPhoneNumber(value.toString()));
      }}
      {...rest}
      style={{
        width: "100%",
      }}
      formatter={formatPhoneNumber}
      parser={parser}
      keyboard={false}
      onKeyDown={onKeyDown}
    />
  );
}
export default InputPhone;

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  const phoneOnlyNumbers = phoneNumber?.replace(/\D/g, "");

  if (!phoneOnlyNumbers || phoneOnlyNumbers === "-1") return "";

  if (phoneOnlyNumbers.length <= 3) {
    return phoneOnlyNumbers;
  }

  if (phoneOnlyNumbers.length <= 6) {
    return phoneOnlyNumbers.replace(/(\d{3})(\d{1,3})/, "$1.$2");
  }

  return phoneOnlyNumbers.replace(/(\d{3})(\d{3})(\d{1,4})/, "$1.$2.$3");
};

export const phoneValidator = (value: string | undefined) => {
  if (!value) {
    return Promise.reject(new Error("Phone number is required"));
  }
  if (!/^\d{3}\.\d{3}\.\d{4}$/.test(value)) {
    return Promise.reject(new Error("Phone numbers must contain 10 digits."));
  }
  return Promise.resolve();
};
