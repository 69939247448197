import { useEffect, useState } from "react";
import moment from "moment";
import { Form, Input, Select, DatePicker } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import styles from "./FormFields.module.scss";
import { fields } from "./FormFields.schema";
import LocationsInput from "shared/components/LocationsInput";
import { dateToEST } from "screens/designStudio/helpers";

type Props = {
  disabled?: boolean;
};

export const SettingsFields = ({ disabled }: Props) => (
  <div className={styles.container}>
    <div className={styles.fieldsContainer}>
      <Form.Item
        labelAlign="left"
        label={fields.name.label}
        name={fields.name.key}
        rules={[
          {
            required: fields.name.required,
            message: `${fields.name.label} is required.`,
          },
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label={fields.description.label}
        name={fields.description.key}
        rules={[
          {
            required: fields.description.required,
            message: `${fields.description.label} is required.`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label={fields.locations.label}
        name={fields.locations.key}
        rules={[
          {
            required: fields.locations.required,
            message: `${fields.locations.label} is required.`,
          },
        ]}
      >
        <LocationsInput disabled={disabled} />
      </Form.Item>

      <Form.Item
        requiredMark={fields.salesCycle.required ? true : "optional"}
        label={fields.salesCycle.label}
        name={fields.salesCycle.key}
        rules={[
          {
            required: fields.salesCycle.required,
            message: `${fields.salesCycle.label} is required.`,
          },
        ]}
      >
        <Select mode="multiple" options={fields.salesCycle.options} />
      </Form.Item>
      <Form.Item
        requiredMark={fields.audience.required ? true : "optional"}
        label={fields.audience.label}
        name={fields.audience.key}
        rules={[
          {
            required: fields.audience.required,
            message: `${fields.audience.label} is required.`,
          },
        ]}
      >
        <Select mode="multiple" options={fields.audience.options} />
      </Form.Item>
      <Form.Item
        requiredMark={fields.salesCycle.required ? true : "optional"}
        label={fields.materialType.label}
        name={fields.materialType.key}
        rules={[
          {
            required: fields.materialType.required,
            message: `${fields.materialType.label} is required.`,
          },
        ]}
      >
        <Select mode="multiple" options={fields.materialType.options} />
      </Form.Item>
    </div>
  </div>
);

export const ComponentsFields = ({
  lang,
  active,
}: {
  active: boolean;
  lang: "en" | "es";
}) => {
  const [expirationDate, setExpirationDate] = useState<
    moment.Moment | undefined
  >(undefined);

  const formNumberField =
    lang === "en" ? fields.englishFormNumber : fields.spanishFormNumber;

  const { uploadForm } = useSalesEnablementContext();

  useEffect(() => {
    if (uploadForm?.getFieldValue("expirationDate")) {
      setExpirationDate(
        dateToEST(moment.utc(uploadForm.getFieldValue("expirationDate"))),
      );
    } else {
      setExpirationDate(undefined);
    }
  }, [active, uploadForm]);

  return (
    <div className={styles.container} style={{ padding: `12px 0 0` }}>
      <div className={styles.fieldsContainer}>
        <div className={styles.expirationDateContainer}>
          <Form.Item
            labelAlign="left"
            label={fields.expirationDate.label}
            tooltip={{
              title: `
               Set an expiration date to automate when this template is unpublished and inaccessible to agents.
               Admins can update the expiration date to re-publish an expired template.
          `,
              icon: <InfoCircleOutlined />,
            }}
            name={fields.expirationDate.key}
            key={fields.expirationDate.key}
            rules={[
              {
                required: fields.expirationDate.required,
                message: `${fields.expirationDate.label} is required.`,
              },
            ]}
            valuePropName={"date"}
          >
            <DatePicker
              value={expirationDate}
              onChange={date => {
                if (!date) return setExpirationDate(undefined);
                setExpirationDate(dateToEST(moment.utc(date)));
              }}
              picker="month"
              name={fields.expirationDate.key}
              format={date => dateToEST(date).format("M/YY")}
              placeholder="Please select"
              className={styles.datePicker}
              disabledDate={d => {
                return dateToEST(moment.utc()).isSameOrAfter(
                  dateToEST(d),
                  "month",
                );
              }}
            />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label={formNumberField.label}
            name={formNumberField.key}
            key={formNumberField.key}
            rules={[
              {
                required: formNumberField.required,
                message: `Form Number is required.`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
