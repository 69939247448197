import { Drawer, Modal, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Disclosure } from "shared/types/salesEnablement";
import { salesEnablementBlueColor } from "shared/constants/salesEnablement";
import {
  useCreateDisclosure,
  useDisclosure,
  useUpdateDisclosure,
} from "shared/hooks/admin/useDisclosures";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import { DrawerFooter } from "../shared/DrawerFooter";
import { DisclosureForm } from "./disclosureDrawer/DisclosureForm";
import { ROUTES } from "./utils/constants";

type Props = {
  isVisible: boolean;
  disclosureId?: string;
};

export const DisclosureDrawer = ({ isVisible, disclosureId }: Props) => {
  const navigate = useNavigate();
  const { mutate: createDisclosure, isLoading: isCreating } =
    useCreateDisclosure();
  const { mutate: updateDisclosure, isLoading: isUpdating } =
    useUpdateDisclosure();
  const { disclosure } = useDisclosure(disclosureId);

  const onClose = () => {
    navigate(`${ROUTES.home}${location.search}`);
  };

  const onFinish = async (disclosure: Disclosure) => {
    const id = disclosure?.id;
    if (id) {
      const titleText = "Are you sure you want to edit this disclosure?";
      const detailText =
        "Your edit will automatically update in agents' existing and in-progress Marketing Materials.";
      Modal.confirm({
        title: <b>{titleText}</b>,
        content: detailText,
        onOk: () => {
          updateDisclosure(disclosure, { onSuccess: onSuccess });
        },
        icon: (
          <ExclamationCircleOutlined
            style={{ color: salesEnablementBlueColor }}
          />
        ),
        okText: "Confirm",
      });
      return;
    }
    createDisclosure(disclosure, { onSuccess: onSuccess });
  };

  const onSuccess = () => {
    notification.success({
      message: "The disclosure has been saved.",
    });
    onClose();
  };

  const formId = "disclosure-form";

  return (
    <Drawer
      title={`${disclosureId ? "Edit" : "New"} Disclosure`}
      width={928}
      visible={isVisible}
      forceRender
      onClose={onClose}
      closable={false}
      destroyOnClose
      footer={
        <DrawerFooter
          formId={formId}
          isLoading={isCreating || isUpdating}
          onClose={onClose}
        />
      }
    >
      {isVisible && (
        <DisclosureForm
          disclosure={disclosure}
          formId={formId}
          onFinish={onFinish}
        />
      )}
    </Drawer>
  );
};
