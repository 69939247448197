import { Button, Form, FormItemProps, Input, Typography } from "antd";
import { useMemo, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  salesEnablementBlueColor,
  specialStateLicensePattern,
  specialStateMessage,
} from "shared/constants/salesEnablement";
import { isSpecialState } from "../hooks/agentManagement";
import { StateKey } from "shared/constants/states";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import { baseRules } from "../utils/helpers";

type Props = {
  fieldName: string[];
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
};

export const LicenseField = ({ fieldName, label, onChange }: Props) => {
  const [isInputVisible, setIsInputVisible] = useState(true);
  const [isFieldValid, setIsFieldValid] = useState(true);
  const form = useMaterialFormInstance();
  const states: StateKey[] = form.getFieldValue("locations") ?? [];
  const specialStates = states.filter(isSpecialState);
  const selectedStatesMessage = specialStates
    ?.map(stateId => specialStateMessage[stateId])
    .join(" / ");

  const helpMessage = `Per ${specialStates.join(
    "/",
  )} DOI, please enter this full text before your number: ${selectedStatesMessage}`;

  const formProps: FormItemProps = useMemo(() => {
    if (specialStates.length) {
      return {
        label: specialStates
          .map(stateId => `${stateId} License No.`)
          .join(" / "),
        rules: [
          {
            required: true,
            pattern: new RegExp(
              specialStates.length > 1
                ? `^${specialStateLicensePattern}( \/ (${specialStateLicensePattern}))$`
                : `^${specialStateLicensePattern}$`,
            ),
            validator: (_, value: string) => {
              const pattern = new RegExp(
                specialStates.length > 1
                  ? `^${specialStateLicensePattern}( \/ (${specialStateLicensePattern}))$`
                  : `^${specialStateLicensePattern}$`,
              );
              if (value?.match(pattern)) {
                setIsFieldValid(true);
                return Promise.resolve();
              }
              setIsFieldValid(false);
              return Promise.reject(helpMessage);
            },
          },
        ],
      };
    } else {
      return {
        label,
        rules: baseRules("Please enter a License Number or remove this field."),
      };
    }
  }, [helpMessage, label, specialStates]);

  const isHelpMessageVisible = isFieldValid && !!specialStates?.length;

  if (!isInputVisible)
    return (
      <Button
        type="link"
        icon={<PlusOutlined />}
        style={{ color: salesEnablementBlueColor }}
        onClick={() => setIsInputVisible(true)}
      >
        Add License Number
      </Button>
    );

  return (
    <>
      <Form.Item name={fieldName} {...formProps}>
        {specialStates?.length ? (
          <Input.TextArea
            placeholder={selectedStatesMessage}
            rows={specialStates.length}
          />
        ) : (
          <Input
            placeholder="License number"
            suffix={
              <DeleteOutlined
                onClick={() => {
                  setIsInputVisible(false);
                  onChange?.("");
                }}
              />
            }
          />
        )}
      </Form.Item>
      {isHelpMessageVisible && (
        <Typography.Text type="secondary">{helpMessage}</Typography.Text>
      )}
    </>
  );
};
