import {
  Space,
  Radio,
  Typography,
  Divider,
  Checkbox,
  Tag,
  Tooltip,
  Spin,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useFeedContext } from "../shared/contexts/FeedContext";
import styles from "./ExportDrawer.module.scss";
import { getAllAssetBatchIds } from "./utils/getAllAssetBatchIds";
import { getHTMLTemplatesMap } from "./utils/getHTMLTemplatesMap";
import { getValidAssetBatches } from "./utils/getValidAssetBatches";
import { ExportDestination } from "./types";
import { useFetchTemplates } from "./useFetchTemplates";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { isFeatureEnabled } from "utils/helpers";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const isExportToOffPlatformEnabled = isFeatureEnabled(
  "ENABLE_EXPORT_TO_OFF_PLATFORM",
  false,
);

export const ExportSettings = () => {
  const {
    selectedAssetBatches,
    onSelectAssetBatches,
    assetBatchesForSelectedRows,
  } = useFeedContext();
  const { exportDestination, setExportDestination } =
    useAdLibraryExportContext();

  const { templates, isLoading: isFetchingTemplates } = useFetchTemplates(
    assetBatchesForSelectedRows,
  );
  const HTMLTemplatesMap = getHTMLTemplatesMap(
    assetBatchesForSelectedRows,
    templates,
  );

  const validAssetBatches = getValidAssetBatches(
    exportDestination,
    assetBatchesForSelectedRows,
    HTMLTemplatesMap,
  );

  const allAssetBatchesSelected =
    selectedAssetBatches.length === validAssetBatches.length;
  const someAssetBatchesSelected =
    selectedAssetBatches.length > 0 && !allAssetBatchesSelected;

  const onExportDestinationChange = (value: ExportDestination) => {
    if (value === ExportDestination.AdLibrary) {
      const validSelectedAssetBatches = selectedAssetBatches.filter(
        assetBatchId => !HTMLTemplatesMap[assetBatchId],
      );

      onSelectAssetBatches(validSelectedAssetBatches);
    }

    setExportDestination(value);
  };

  const onAllCheckboxChange = () => {
    if (allAssetBatchesSelected) {
      onSelectAssetBatches([]);
    } else {
      let allAssetBatchIds = getAllAssetBatchIds(assetBatchesForSelectedRows);

      if (exportDestination === ExportDestination.AdLibrary) {
        allAssetBatchIds = allAssetBatchIds.filter(
          assetBatchId => !HTMLTemplatesMap[assetBatchId],
        );
      }
      onSelectAssetBatches(allAssetBatchIds);
    }
  };

  const onCheckBoxChange = (e: CheckboxChangeEvent, assetBatchId: string) =>
    e.target.checked
      ? onSelectAssetBatches([...selectedAssetBatches, assetBatchId])
      : onSelectAssetBatches(
          selectedAssetBatches.filter(id => id !== assetBatchId),
        );

  return (
    <Spin
      spinning={isFetchingTemplates}
      size="large"
      indicator={<Loading3QuartersOutlined spin />}
      wrapperClassName={styles.spinWrapper}
    >
      <div className={styles.exportSettingsWrapper}>
        <div className={styles.exportSettingsColumn}>
          <Typography.Text type="secondary">
            Asset batches to export
          </Typography.Text>
          <Divider className={styles.divider} />
          <Space direction="vertical" className={styles.checkboxContainer}>
            <Checkbox
              checked={allAssetBatchesSelected}
              indeterminate={someAssetBatchesSelected}
              onChange={onAllCheckboxChange}
            >
              All
            </Checkbox>
            {assetBatchesForSelectedRows.map(({ name, assetBatchId }) => {
              const val = assetBatchId || "";
              const disabled =
                exportDestination === ExportDestination.AdLibrary &&
                HTMLTemplatesMap[val];

              return (
                <Tooltip
                  key={val}
                  placement="topLeft"
                  trigger="hover"
                  title={
                    disabled
                      ? "Asset batches containing HTML templates are not allowed for this export type"
                      : ""
                  }
                >
                  <Checkbox
                    checked={selectedAssetBatches.includes(val)}
                    onChange={e => onCheckBoxChange(e, val)}
                    data-cy={`assetBatchesToExport-${val}`}
                    disabled={disabled}
                  >
                    <Tag className={styles.tag} key={name}>
                      {name}
                    </Tag>
                  </Checkbox>
                </Tooltip>
              );
            })}
          </Space>
        </div>
        <div className={styles.exportSettingsColumn}>
          <Typography.Text type="secondary">Export for</Typography.Text>
          <Divider className={styles.divider} />
          <Radio.Group
            className={styles.radioGroup}
            onChange={event => onExportDestinationChange(event.target.value)}
            value={exportDestination}
          >
            <Space direction="vertical">
              <Radio value={ExportDestination.AdLibrary}>Ad Library</Radio>
              <Radio value={ExportDestination.CSVDownload}>CSV download</Radio>
              {isExportToOffPlatformEnabled && (
                <Radio value={ExportDestination.GoogleSpreadsheet}>
                  Spreadsheet-Enabled Portal
                </Radio>
              )}
            </Space>
          </Radio.Group>
        </div>
      </div>
    </Spin>
  );
};
