import { TAssetBatch } from "shared/types/assetExporter";
import { ExportDestination } from "../types";

export const getValidAssetBatches = (
  exportDestination: ExportDestination | null,
  assetBatchesForSelectedRows: TAssetBatch[],
  HTMLTemplatesMap: Record<string, boolean>,
): TAssetBatch[] => {
  if (exportDestination === ExportDestination.AdLibrary) {
    return assetBatchesForSelectedRows.filter(
      assetBatch => !HTMLTemplatesMap[assetBatch?.assetBatchId ?? ""],
    );
  }
  return assetBatchesForSelectedRows;
};
