import { Input, Form, Modal, Select, Alert } from "antd";
import ExpandableParagraph from "shared/components/ExpandableParagraph";
import LocationsInput from "shared/components/LocationsInput";
import { StateKey } from "shared/constants/states";
import {
  MarketingMaterial,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import { Language } from "shared/types/salesEnablement";
import MarketingMaterialFormComponent from "./MarketingMaterialFormComponent";
import {
  CloseCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  useMarketingMaterialsContext,
  useMaterialFormInstance,
} from "../marketingMaterials/MarketingMaterialsProvider";
import { ProductTypeOfferInput } from "./marketingMaterialForm/ProductTypeOfferInput";
import {
  getInitialValues,
  useGetInitialValues,
} from "./hooks/useGetInitialValues";
import FromTemplate from "./marketingMaterialForm/FromTemplate";
import { Languages } from "utils/helpers.salesEnablement";
import { baseRules, getLocationDisclosureRule } from "./utils/helpers";
import { useUserAsAgentProfile } from "./hooks/agentManagement";

type FormProps = Readonly<{
  agentLocations: StateKey[];
  disabledLocations: StateKey[];
  readonly?: boolean;
  formInitialValues?: MarketingMaterial["fields"];
}>;

export const marketingMaterialFormId = "marketing-material-form";

const MarketingMaterialForm = ({
  agentLocations,
  disabledLocations,
  readonly,
  formInitialValues,
}: FormProps) => {
  const form = useMaterialFormInstance();
  const userAsAgent = useUserAsAgentProfile();

  const {
    material,
    template,
    language,
    onValuesChange,
    setLanguage,
    initialValues,
    hasProductLayer,
    disclosureFieldId,
  } = useMarketingMaterialsContext();

  const disclosureText = initialValues?.fields?.[disclosureFieldId ?? ""];

  const disabled = !!material?.templateErrorStatus || readonly;

  const readonlyInitialValues = useGetInitialValues(
    template,
    {
      ...material,
      fields: formInitialValues ?? material?.fields,
    } as MarketingMaterial,
    language,
  );

  const templateLanguages = Object.keys(template.files) as Language[];

  const onLanguageChange = (lang: Language) => {
    if (lang === language) return;
    Modal.confirm({
      title: (
        <b>Are you sure you want to change the marketing material language?</b>
      ),
      icon: <ExclamationCircleOutlined style={{ color: "#1890FF" }} />,
      okText: "Change Language",
      cancelText: "Cancel",
      okButtonProps: { type: "primary", danger: true },
      onOk: () => {
        const newInitialValues = getInitialValues(userAsAgent, lang, template);
        setLanguage(lang);
        form.resetFields();
        form.setFieldsValue({ fields: newInitialValues, language: lang });
      },
    });
  };

  return (
    <Form<MarketingMaterialTableItem>
      id={marketingMaterialFormId}
      layout="vertical"
      initialValues={
        formInitialValues ? readonlyInitialValues.initialValues : initialValues
      }
      form={form}
      onValuesChange={onValuesChange}
      style={{ overflow: "auto", paddingRight: "24px" }}
    >
      {!disclosureText && material?.materialErrorStatus && (
        <Alert
          style={{ alignItems: "flex-start" }}
          message={material.materialErrorStatus.message}
          type="error"
          showIcon
          icon={<CloseCircleFilled style={{ marginTop: 2 }} />}
        />
      )}
      <Form.Item hidden name="id" />
      <ExpandableParagraph>{template.description}</ExpandableParagraph>
      <Form.Item
        label="Marketing Material Name"
        name="name"
        rules={baseRules("Please enter a name")}
        style={{
          marginBottom: "0",
        }}
      >
        <Input placeholder="Name" disabled={disabled} />
      </Form.Item>
      <FromTemplate template={template} />

      <Form.Item label="Language" name="language" required>
        <Select
          onSelect={onLanguageChange}
          disabled={templateLanguages.length == 1 || disabled}
        >
          {templateLanguages.map(lang => (
            <Select.Option key={lang} value={lang}>
              {Languages[lang].label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {disclosureFieldId && !hasProductLayer && (
        <ProductTypeOfferInput
          disabled={disabled}
          disclosureFieldId={disclosureFieldId}
        />
      )}
      <Form.Item
        label="State(s) to be Distributed In (Ind) / Account Situs State (Grp)"
        name="locations"
        required
        dependencies={[["fields", disclosureFieldId ?? ""], ["language"]]}
        rules={[
          { required: true, message: "Please select at least one state." },
          getLocationDisclosureRule(disclosureFieldId),
        ]}
      >
        <LocationsInput
          availableLocations={agentLocations}
          disabledLocations={disabledLocations}
          disabledTooltip="This marketing material can't be used in this location"
          disabled={disabled}
        />
      </Form.Item>

      <MarketingMaterialFormComponent
        disabled={disabled}
        file={template.files[language]}
        language={language}
        disclosureFieldId={disclosureFieldId}
      />
    </Form>
  );
};

export default MarketingMaterialForm;
