import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useDeleteAgentProfiles } from "shared/hooks/salesEnablement/useAgentProfiles";
import { pluralize } from "utils/terms";

export const useDeleteProfilesPrompt = () => {
  const { mutateAsync: deleteAgentProfiles } = useDeleteAgentProfiles();
  const showDeletePrompt = (selectedAgentProfileIds: string[]) => {
    const text = selectedAgentProfileIds.length > 1 ? "these" : "this";
    Modal.confirm({
      title: (
        <b>
          Are you sure you want to delete {text} agent{" "}
          {pluralize("profile", selectedAgentProfileIds.length)}?
        </b>
      ),
      icon: <ExclamationCircleOutlined style={{ color: "#1890FF" }} />,
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { type: "primary", danger: true },
      onOk: () => {
        return deleteAgentProfiles(selectedAgentProfileIds);
      },
    });
  };

  return showDeletePrompt;
};
